import {withGlobal} from '../../shared/app'
import {MapViewFromLatLng} from '../../misc/gmap'
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import momenttz from 'moment-timezone'
import {BtnEdit} from '../../widgets/btn-edit'
import {Btn} from '../../misc/buttons'
import LinkifyText from '../../misc/linkify-text'

import {H1} from '../../misc/headings'
import {string} from '../../../lib/utils'

const PromoPartner = ({page, t}) => {
  const {partner, agency} = page
  const {promo, logo} = partner.config || {}
  const item = agency.key == 'root-agency' ? partner : agency

  if (!promo || partner?.key !== 'inmemori')
    return (
      <div className='partner-promo d-flex justify-content-between align-items-start'>
        <div className='pr-3 pre-line'>
          {t(`partner.no-partner`)}{' '}
          <a
            href='https://fr.inmemori.com?utm_source=tribute&utm_medium=new-im-block&utm_term=DécouvrirInmemori'
            target='_blank'
            rel='noreferrer'
            className='text-nowrap'>
            {t(`partner.inmemori-link`)}
          </a>
          {'.'}
        </div>

        <style jsx>
          {`
            .partner-promo {
              margin-bottom: 30px;
            }
            img {
              max-width: 130px;
              max-height: 80px;
            }
            @media (max-width: 991px) {
              // lg down
              img {
                max-width: 120px;
                max-height: 74px;
              }
            }
          `}
        </style>
      </div>
    )

  return (
    <div className='partner-promo d-flex justify-content-between align-items-start'>
      <div className='pr-3 pre-line'>{t(`partner.promo-${promo}`, item)}</div>
      <div>
        {logo && (
          <img
            src={`https://res.cloudinary.com/inmemori/image/upload/fl_lossy,f_auto/partners/${partner.key}`}
          />
        )}
      </div>

      <style jsx>
        {`
          .partner-promo {
            margin-bottom: 30px;
          }
          img {
            max-width: 130px;
            max-height: 80px;
          }
          @media (max-width: 991px) {
            // lg down
            img {
              max-width: 120px;
              max-height: 74px;
            }
          }
        `}
      </style>
    </div>
  )
}

const Skeleton = ({page, t, flow, hasRole, uiref = {}}) => (
  <div className='card'>
    <div className='card-body'>
      <H1>{t('places.h1')}</H1>

      <PromoPartner page={page} t={t} hasRole={hasRole} />

      <div className='skeleton text-center'>
        <Btn
          className='btn btn-link text-primary'
          onClick={() => flow.set('edit-places', {uiref})}
          value={t('places.cta.add')}
          icon='https://res.cloudinary.com/inmemori/image/upload/v1589806556/web-assets/picto/calendar.svg'
        />
        <div className='text text-lighter col-12 col-md-10 m-auto'>{t(`skeleton.places`)}</div>
        <img
          className='img-fluid'
          src='https://res.cloudinary.com/inmemori/image/upload/v1592498631/web-assets/skeleton-places.png'
        />
      </div>
    </div>

    <style jsx>
      {`
        .skeleton {
          position: relative;
        }
        .skeleton .text {
          // position: absolute;
          // left: 0;
          // right: 0;
          // top: 0;
          // margin: 0 auto;
          // margin-top: 100px;
        }
      `}
    </style>
  </div>
)

const Block = ({page, hasRole, t, flow, Products, uiref = {}, locations}) => {
  const editMode = hasRole('manager,broker')

  const tz = moment.tz.guess()

  if (!locations.length && editMode)
    return <Skeleton page={page} flow={flow} t={t} hasRole={hasRole} uiref={uiref} />

  if (locations.length)
    return (
      <div className='card'>
        <div className='card-body'>
          <H1>{t('places.h1')}</H1>

          <PromoPartner page={page} t={t} hasRole={hasRole} />

          {locations.map((location, i) => {
            const date = moment(location.meta.date).tz(location.tz || tz)
            const infos = location.meta.info && string.urlify(location.meta.info)
            return (
              <div className='item' key={i}>
                <div className='d-flex flex-column flex-md-row justify-content-between align-items-lg-center'>
                  <div className='z1'>
                    {location.meta.date && (
                      <div>
                        <div className='d-none d-md-block'>
                          <h3>{string.capitalize(date.format('dddd'))}</h3>
                          <h4>{date.format(t('dates.day'))}</h4>
                          <div className='text-light mt-3'>
                            <i className='far fa-clock mr-1' /> {date.format(t('dates.time'))}
                          </div>
                        </div>
                        <div className='d-md-none mb-3'>
                          <div className='f2 sbold'>{date.format(t('dates.short'))}</div>
                          <div className='text-light'>
                            <i className='far fa-clock mr-1' /> {date.format(t('dates.time'))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='z2 pr-5 d-none d-lg-block'>
                    {location.latlng && !!Object.keys(location.latlng).length && (
                      <MapViewFromLatLng latlng={location.latlng} height='120px' />
                    )}
                  </div>

                  <div className='z3'>
                    <h3 className='d-none d-md-block'>
                      {location.meta.customType || t(`places.types.${location.meta.type}`)}
                    </h3>
                    <div className='d-md-none f2 sbold'>
                      {location.meta.customType || t(`places.types.${location.meta.type}`)}
                    </div>

                    {location.meta.privacy && (
                      <div className='py-2 py-md-0 privacy text-italic text-light'>
                        {t('places.labels.privacy')}
                      </div>
                    )}

                    <div className='text-light'>
                      {location.displayName && <div>{location.displayName}</div>}
                      {location.address && <div>{location.address}</div>}
                    </div>

                    {location.meta.broadcast && (
                      <a
                        rel='noreferrer'
                        href={location.meta.broadcast}
                        target='_blank'
                        className='map-link d-block mt-2'>
                        <img
                          className='mr-2'
                          width='20px'
                          src='https://res.cloudinary.com/inmemori/image/upload/web-assets/picto/stream.svg'
                        />
                        {t('places.cta.broadcast')}
                      </a>
                    )}
                    {Products.Flower.isActive() && location.deliverable && !location.meta?.disableFlower && (
                      <a
                        onClick={() =>
                          flow.set('ft-flower', {
                            placeIndex: i,
                            uiref: {...uiref, innerPosition: i + 1}
                          })
                        }
                        className='map-link d-block mt-2'>
                        <img
                          className='mr-2'
                          width='20px'
                          src='https://res.cloudinary.com/inmemori/image/upload/web-assets/picto/flowers-locations.svg'
                        />
                        {t('places.cta.flower')}
                      </a>
                    )}
                    {infos && infos.includes('</a>') && (
                      <div
                        className='text-light mt-2 font-italic pre-line'
                        dangerouslySetInnerHTML={{__html: infos}}
                      />
                    )}
                    {infos && !infos.includes('</a>') && (
                      <div className='text-light mt-2 font-italic pre-line'>{infos}</div>
                    )}
                  </div>
                </div>

                {editMode && (
                  <BtnEdit className='mt-3' onClick={() => flow.set('edit-places', {uiref})} />
                )}
              </div>
            )
          })}

          {editMode && locations.length < 6 && (
            <div className='item'>
              <Btn
                className='btn btn-link text-primary'
                onClick={() => flow.set('edit-places', {uiref})}
                value={t('places.cta.add')}
                icon='https://res.cloudinary.com/inmemori/image/upload/v1589806556/web-assets/picto/calendar.svg'
              />
            </div>
          )}

          {page.info && (
            <div className='mt-5'>
              {' '}
              <H1>{t('places.labels.info')}</H1>
              <div className='item pre-line d-flex flex-column align-items-start'>
                <LinkifyText text={page.info} />
                {editMode && locations.length < 6 && (
                  <BtnEdit className='mt-3' onClick={() => flow.set('edit-places', {uiref})} />
                )}
              </div>
            </div>
          )}
        </div>

        <style jsx global>
          {`
            .mapview-container {
              // margin: -20px;
              // margin-top: 10px;
            }
          `}
        </style>

        <style jsx>
          {`
            .border-bottom {
              // border-top: 1px solid #dadada;
            }

            .item {
              border-bottom: 1px solid #dadada;
              padding: 20px 0;
              // font-size: 18px;
            }
            .item:first-child {
              padding-top: none;
            }
            .item:last-child {
              border-bottom: none;
              padding-bottom: none;
            }

            .map-link {
              font-size: 14px;
              cursor: pointer;
              font-weight: bold;
            }

            .z1 {
              flex: 0.8;
            }
            .z2 {
              flex: 1;
            }
            .z3 {
              flex: 2;
            }

            .privacy {
              margin-top: -10px;
              margin-bottom: 10px;
            }
          `}
        </style>
      </div>
    )

  return null
}

export default withGlobal(Block)
